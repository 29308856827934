// const IndexPage = () => (
//   <Layout>
//     <SEO title="Home" />
//     <h1>Hi people</h1>
//     <p>Welcome to your new Gatsby site.</p>
//     <p>Now go build something great.</p>
//     <StaticImage
//       src="../images/gatsby-astronaut.png"
//       width={300}
//       quality={95}
//       formats={["AUTO", "WEBP", "AVIF"]}
//       alt="A Gatsby astronaut"
//       style={{ marginBottom: `1.45rem` }}
//     />
//     <p>
//       <Link to="/page-2/">Go to page 2</Link> <br />
//       <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
//     </p>
//   </Layout>
// )

// export default IndexPage


import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import { Container } from "react-bootstrap"

const IndexPage2 = () => {
  const { placeholderImage123 } = useStaticQuery(
          graphql`
      query {
        placeholderImage123: file(relativePath: { eq: "eci-process.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 50, 
              webpOptions: {quality: 50}, 
              width: 2000,
              placeholder: BLURRED,
              blurredOptions: {width: 1000},
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const pluginImage = getImage(placeholderImage123);
  // const crowdImage = getImage(crowd);

  return (
    <Layout>
      <SEO title="ECI Process" />
      <BgImage image={pluginImage} className="subhead">
        <div className="color-overlay d-flex pt-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <h1>The ECI Process</h1>
            </Container>
          </div>
        </div>
      </BgImage>
      <Container>
        <p className="lead orangeBarBelow">
        Event Consulting International provides a spectrum of services fine-tuned to make your event, meeting or trip a great success. Our events are fresh, fun and always anticipated with enthusiasm.
        </p>
      </Container>
      <Container className="singleCol">
        <p>Here’s how we get things done:</p>
        <p>First, we ask a lot of questions because understanding your audience, messages and goals allows us to deliver the impact and experience you expect. Then we go to work!</p>
        <h2>Plan</h2>
        <p><strong>Create a Theme.</strong> For every themed event, we provide assistance from concept to delivery. We provide props, planning, entertainment, themed invitations and decorations, and a host of other services complementary to your theme.</p>
        <p><strong>Select a Site.</strong> Selecting the perfect venue can be daunting. We make it easier by providing options that stem from our vast experience, meticulous research capabilities and state-of-the-art tools.</p>
        <p>Based on your requirements, we’ll hone in on the best options available and provide a comparison-ready budget for each. We always consider the lodging and services a site can provide, how easy it is for people to get there, weather, activities and the attractions that the surrounding area has to offer when making our selections. Together we’ll narrow down the options and then ECI will set up a site inspection so you can get an up-close view of your venue.</p>
        <p><strong>Identify Activity Options.</strong> When we consider a site we also take a look at the activity options for the area and how they might relate to your theme, content or unique needs. We’ll make sure there is something for everyone, from the most adventurous to the most luxurious activities. And when it comes to incentive type trips, we’ll make sure each of your winners and their guests feel special and overjoyed at the personal attention they receive.</p>
        <p><strong>Implement Exciting Team-Building Experiences.</strong> Team building activities teach essential collaborative skills and help employees build trust in each other, which improves overall morale and productivity. Our on-theme team building experiences are specifically created to motivate your employees and to get them talking and collaborating. Our exercises are focused on introducing out of the ordinary situations for the team to solve together, fostering creative problem solving that will transfer back to the workplace long after the activities are complete.</p>
        <hr />
        <h2>Personalize</h2>
        <p><strong>Brand Your Event.</strong> Give your event its own memorable, branded look and feel. Our creative team can design unique logos or artwork for your event and place it on your registration website, mailings, letterhead and other promotional media.</p>
        <p><strong>Produce Program Agenda.</strong> People love to know what’s coming. We can design and produce an easy to follow program agenda to help generate excitement and get people to your activities on time. Let us do the detail work with you giving feedback and final approval along the way.</p>
        <p><strong>Create a Mobile App to share agenda details.</strong> Give your guests quick access to your agenda so they can easily show up on time and in the right place. ECI offers a branded mobile app that can be customized to your unique needs giving you the ability to do things like: contact individuals or all of your guests, share speaker information, or even push last minute changes and promotions to your guests’ smartphone. The app is also pre-populated with guest registration information gathered by the custom registration website. Using a mobile app is a great way to take the guesswork out of your day and theirs.</p>
        <p><strong>Develop a Custom On-line Registration.</strong> We build custom registration websites to collect guest information in the most user-friendly way possible – even populating fields with information from individuals’ previous registration data to make the procedure quick and easy. Your custom website registration will collect guests’ contact information, show a program of events, hotel and destination information, meeting content, and if needed, can collect data such as: breakout or class choices, activity choices, protected payment information, speaker biographies, sponsor and exhibitor data.</p>
        <p><strong>Photography and Video.</strong> Reliving ‘aha’ moments and bonding experiences after your program is over can refresh motivation generated by your event. ECI can bring together professional photographers and/or videographers to capture your meetings, special moments and experiences so you can share them with your team after all is said and done. We can deliver raw footage and photos if you’d like to customize on your own, or we can create branded videos or photo albums with key messages and sound.</p>
        <hr />

        <p className="text-center">
          <StaticImage
              src="../images/PST_Convergence2016_681.jpg"
              width={700}
              quality={72}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="A Corporate Meeting"
              className="rounded"
            />
        </p>
        <hr />
        <h2>Working with Vendors</h2>
        <p><strong>Manage Negotiations.</strong> At ECI, saving you time and money is paramount. We are proud of our contract negotiation skills and buying power, sharply honed after working for many years and through many market fluctuations. We have long standing relationships with Hotels, Airlines and Vendors to secure beneficial rates for you with known quality providers. Your ECI expert will manage each vendor and follow your requests and requirements through to successful fruition taking as little time as possible from you.</p>
        <p><strong>Maintain Hotel and Airline Blocks.</strong> We are good at herding cats. We will supply and maintain the rooming list of guests at the hotels and we will ticket airline travel for all of your guests while staying in contact with them directly to assure that they are properly scheduled for flights.</p>
        <hr />
        <h2>Watch The Details</h2>
        <p><strong>Prepare a Comprehensive Budget.</strong> You want to know what you are spending, where it’s going and to be able to compare cost options. No problem. We’ll provide a detailed budget estimate for all viable event options so you can compare them side by side to help make the best selection. And at the completion of the program, we’ll hand over an accurate set of requested reports and invoices for your records.</p>
        <p><strong>Data Management.</strong> Throughout the event, we track all kinds of data:</p>
        <ul>
          <li>guest lists</li>
          <li>shipping manifests</li>
          <li>ground transportation lists</li>
          <li>rooming lists</li>
          <li>activity lists for individuals’ and group activities</li>
          <li>arrival and departure lists</li>
          <li>guest gift lists</li>
          <li>dine-around lists</li>
          <li>break-out meeting lists and schedules</li>
          <li>audio-visual equipment and labor information</li>
          <li>mailing lists</li>
          <li>name badge lists</li>
        </ul>
        <p>Once your event is wrapped up, you will receive accurate final reports, event debriefs and program surveys to refer to when planning your future events and programs.</p>
        <p><strong>Customize Reports to Suit Your Needs.</strong> Reports will be created for you to use before the program, during the program, post-program and for future programs. We create reports on the budget, site identification and research, guest details, the air travel, ground transportation, mailings, event attendance, premiums, special needs, breakout meetings and class breakdowns, payment collection and many more. Additional reports will be created at your request.</p>
        <p><strong>Prepare Final Recap of Program Related Expenses.</strong> A detailed final-costs document will be presented after the program has been completed. Don’t worry, all costs will have been previously approved by you. No expense surprises!</p>
        <p><strong>Expect the Unexpected and Be Delighted.</strong> We are there by your side throughout your event to handle all the production aspects and manage any little surprises that might crop up. That way you can enjoy your guests, spend your time hosting your VIPs and maybe even join the conga line.</p>
      </Container>
      {/* <BgImage image={crowdImage}>
        Test
        <div className="color-overlay d-flex py-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <p className="lead"><em>"Safely Processing and Delivering Superior Value and Service to Our Customers for over 100 years."</em></p>
            </Container>
          </div>
        </div>
      </BgImage> */}
      {/* crowd.jpg */}
    </Layout>
          
  )
}

export default IndexPage2